<template>
    <v-container>
        <v-form
            v-model="valid"
        >
            <v-row>
                <v-col
                    cols="7"
                >
                    <div class="desc">
                        <b>{{ item.product.title }}</b>
                    </div>
                    <div
                        class="desc"
                        style="width:300px;
                        height:60px;overflow:hidden;text-overflow: ellipsis;"
                    >
                        {{ item.product.description }}
                        <b v-if="item.product.maxAllowedEntrances != null && item.product.maxAllowedEntrances > 0">{{ $t('basket-info-amountOfEntrances') }}</b> {{ item.product.maxAllowedEntrances }}
                    </div>
                </v-col>
                <v-col
                    cols="2"
                    class="text-center"
                >
                    {{ item.amount }}
                </v-col>
                <v-col
                    cols="3"
                    class="text-right"
                >
                    {{ globalConvertMinorCurrencyToLocal(item.product.priceIncludingVatInMinorCurrency) }}
                </v-col>
            </v-row>
        </v-form>
    </v-container>   
</template>

<script>
export default {
    props: {
        item: {
            type: Object,
            required: true
        },
        preSelectedRelationId: {
            type: Number,
            default: null
        },
        registrationRelationsList: {
            type: Array,
            required: true
        },
        registrationRelationsUsedIdList: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            valid: false,
            selectedRegistrationRelationId : null
        }
    },
    watch: {
        amount: function(val){
            this.amount = val < 0 ? 0 : val
        },
        valid: function(val){
            this.$emit('valid', {
                orderItemId: this.item.id,
                status: val
            })
        }
    },
    created(){
        if(this.preSelectedRelationId && !this.registrationRelationsUsedIdList.find(x => x.relationid === this.preSelectedRelationId)){
            this.selectedRegistrationRelationId = this.preSelectedRelationId
            this.emitOrderItemChange()
        }else{
            this.$emit('orderitemchanged', {
                orderitemid: this.item.id,
                productid: this.item.product.id,
                registrationrelationid: null
            })
        }
    },
    methods: {
        customText(data) {
            let text = ''

            if(data.vehicleBrand)
                text += ' ' + this.$t('registrations-brand') + ': ' + data.vehicleBrand

            if(data.vehicleModel)
                text += ' - ' + this.$t('registrations-model') + ': ' + data.vehicleModel

            return text
        },
        disableItem(data){
            return this.registrationRelationsUsedIdList.find(x => x.relationid === data.id 
                && x.productid === this.item.product.id)
        },
        emitOrderItemChange(){
            this.$emit('orderitemchanged', {
                orderitemid: this.item.id,
                productid: this.item.product.id,
                registrationrelationid: this.selectedRegistrationRelationId
            })
        }
    }
}
</script>